<template>
  <!-- eslint-disable -->
  <div class="p-grid">
    <div class="p-lg-8 p-md-8 p-col-12">
      <img :src="downloadURL" alt="image not found" />
    </div>
    <div class="p-lg-4 p-md-4 p-col-12">
      <h1 class="nin">{{ title }}</h1>
      <!-- Price -->
      <h2 class="nin" v-if="price">
        Price: <span>${{ price }}</span>
      </h2>
      <h2 class="nin" v-else>
        Price: <span @click="contact">contact us</span>
      </h2>
      <!-- Description -->
      <div v-if="description">
        <h3 class="nin">Description:</h3>
        <p class="nin details">{{ description }}</p>
      </div>
      <div v-if="sizes">
        <h3 class="nin">Sizes:</h3>
        <p class="nin details">{{ sizes }}</p>
      </div>
      <div v-if="color">
        <h3 class="nin">Color:</h3>
        <p class="nin details">{{ color }}</p>
      </div>
      <!-- Availability -->
      <h3 class="nin unavailable" v-if="available === 'false'">
        Item is Unavailable
      </h3>
      <!-- Message -->
      <div>
        <h2 class="description">Write a Message:</h2>
        <Textarea v-model="message" rows="5" cols="30" />
      </div>
      <div>
        <Button label="Add to cart" class="p-button-warning" @click="cart" />
        <Button
          label="Back to Customize"
          class="p-button-warning"
          @click="back"
        />
        <div v-if="user === admin" class="edit">
          <Button
            label="Edit"
            class="p-button-danger"
            @click="() => (edit = true)"
          />
          <Button
            label="Change Picture"
            class="p-button-danger"
            @click="() => pictureRef.click()"
          />
          <input type="file" ref="pictureRef" v-on:change="showPic" hidden />
        </div>
        <div v-if="picture" class="upload">
          <span>{{ picture.name }}</span>
          <Button label="Upload" @click="uploadPicture" />
        </div>
      </div>
    </div>
    <!-- To Edit Items by the Developer -->
    <Dialog header="Edit" :closable="false" v-model:visible="edit">
      <div class="edit-dialog">
        <br />
        <div class="label-input">
          <label for="price">Price:</label>
          <input type="number" id="price" v-model="priceVar" />
        </div>
        <br />
        <div class="label-input">
          <label for="description">Description:</label>
          <textarea
            id="description"
            cols="30"
            rows="10"
            v-model="descriptionVar"
          ></textarea>
        </div>
        <br />
        <div class="label-input">
          <label for="sizes">Sizes:</label>
          <input type="text" id="sizes" v-model="sizesVar" />
        </div>
        <br />
        <div class="label-input">
          <label for="color">Color:</label>
          <input type="text" id="color" v-model="colorVar" />
        </div>
        <br />
        <div class="label-input">
          <label for="available">Available:</label>
          <input type="radio" id="yes" :value="true" v-model="availableVar" />
          <label for="yes">Yes</label>
          <input type="radio" id="no" :value="false" v-model="availableVar" />
          <label for="no">No</label>
        </div>
        <br />
        <br />
        <div>
          <Button
            label="Submit Changes"
            class="p-button-outlined p-button-info p-button-raised p-button-lg submit-button"
            @click="updateFirestore"
          />
        </div>
        <br />
        <div>
          <Button
            label="Discard Changes"
            class="p-button-outlined p-button-danger p-button-raised p-button-lg submit-button"
            @click="discardChanges"
          />
        </div>
      </div>
    </Dialog>
  </div>
  <Dialog header="Cart" v-model:visible="displayDialog">
    <h3>Item Added to the Cart</h3>
    <Button label="Go to Cart" class="p-button-warning" @click="go" />
    <Button
      label="Close"
      class="p-button-danger"
      @click="displayDialog = false"
    />
  </Dialog>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import addToCart from "../modules/addToCart";
import { auth, db, storage } from "../firebase/config";
export default {
  props: [
    "available",
    "color",
    "description",
    "downloadURL",
    "name",
    "note",
    "path",
    "price",
    "sizes",
    "title",
    "branch",
    "id",
  ],
  setup(props) {
    const router = useRouter();
    const message = ref(null);
    const displayDialog = ref(false);
    const user = ref("");
    const admin = process.env.VUE_APP_ADMIN;
    const edit = ref(false);
    // updating variables
    const priceVar = ref(props.price);
    const descriptionVar = ref(props.description);
    const sizesVar = ref(props.sizes);
    const colorVar = ref(props.color);
    const availableVar = ref(props.available);
    // picture change
    const pictureRef = ref(null);
    const picture = ref(null);
    const cart = async () => {
      if (auth.currentUser) {
        const { addToCartError } = await addToCart(
          props.title,
          props.name,
          props.downloadURL,
          message.value
        );
        if (!addToCartError.value) {
          displayDialog.value = true;
        }
      } else {
        window.alert("Please Signup or Login first");
      }
    };
    const back = () => {
      router.go(-1);
    };
    const go = () => {
      router.push({ name: "cart" });
    };
    const contact = () => {
      router.push({ name: "ContactUs" });
    };
    const updateFirestore = async () => {
      try {
        await db
          .collection("branches")
          .doc(props.branch)
          .collection("items")
          .doc(props.id)
          .update({
            available: availableVar.value,
            color: colorVar.value,
            description: descriptionVar.value,
            price: priceVar.value,
            sizes: sizesVar.value,
          });
        edit.value = false;
        router.go(-1);
      } catch (error) {
        console.log(error.message);
      }
    };
    const discardChanges = () => {
      priceVar.value = props.price;
      descriptionVar.value = props.description;
      sizesVar.value = props.sizes;
      colorVar.value = props.color;
      availableVar.value = props.available;
      edit.value = false;
    };
    const showPic = (e) => {
      picture.value = pictureRef.value.files[0];
    };
    const uploadPicture = async () => {
      const blob = new Blob([picture.value], { type: picture.value.type });
      try {
        const res = await storage
          .ref(`branches/${props.branch}/${props.name}`)
          .put(blob);
        console.log(res.state);
        if (res.state === "success") {
          window.location.reload();
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    onMounted(() => {
      if (!props.title) {
        back();
      }
      try {
        user.value = auth.currentUser.email;
      } catch (error) {}
    });
    return {
      cart,
      back,
      displayDialog,
      go,
      message,
      contact,
      admin,
      user,
      edit,
      priceVar,
      descriptionVar,
      sizesVar,
      colorVar,
      availableVar,
      updateFirestore,
      discardChanges,
      pictureRef,
      showPic,
      uploadPicture,
      picture,
    };
  },
};
</script>
<style scoped>
img {
  width: 90%;
  margin: 20px;
  border-radius: 20px;
}
.p-button-warning {
  margin: 10px 10px 0;
}
span {
  color: #b1880e;
}
span:hover {
  text-decoration: underline;
  cursor: pointer;
}
.edit {
  width: 320px;
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.edit * {
  flex: 1 1 0;
  margin: 5px;
}
.edit-dialog {
  width: 400px;
  height: 60vh;
}
.label-input {
  display: flex;
  align-items: center;
}
.label-input label {
  flex: 0 0 100px;
}
.submit-button {
  width: 400px;
}
.details {
  margin-left: 15px;
  width: 350px;
}
.unavailable {
  font-weight: bold;
  color: red;
  font-size: 25px;
}
.upload {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
}
.upload span {
  margin-right: 10px;
}
@media (max-width: 767px) {
  img {
    width: 100%;
    margin-left: 10px;
  }
  h1,
  h2,
  h3,
  p,
  div {
    text-align: center;
  }
}
</style>
