import { ref } from "vue";
import { db, auth } from "../firebase/config";
const addToCart = async (item, code, photo, message) => {
  const addToCartError = ref(false);
  try {
    let doc = `${auth.currentUser.email}-${auth.currentUser.uid}`;
    const res = await db
      .collection("users")
      .doc(doc)
      .get();
    if (res.exists) {
      const data = res.data();
      let condition = data.cart.find((o) => {
        return o.code === code && o.completed === "no";
      });
      if (!condition) {
        data.cart = [
          ...data.cart,
          {
            item: item,
            code: code,
            photo: photo,
            message: message,
            completed: "no",
          },
        ];
      } else {
        window.alert(
          "Item is either already present in the Cart or previously purchased"
        );
        error.value = true;
      }
      db.collection("users")
        .doc(doc)
        .update("cart", data.cart);
    } else {
      console.log("user does not exist");
    }
  } catch (error) {
    addToCartError.value = error.message;
  }
  return { addToCartError };
};
export default addToCart;
